import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'redux';
import App from './App';
import './index.css';
import reducers from './reducers';
import ScrollToTop from './ScrollToTop';

// Only import babel-polyfill if it hasn't already been imported.
// https://github.com/babel/babel-loader/issues/401
if (!global._babelPolyfill) {
  require('babel-polyfill');
}

let store = createStore(reducers);

store.subscribe(() => {
  const user = store.getState().user;
  const investments = store.getState().investments;
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('investments', JSON.stringify(investments));
});

const render = (Component) => {
  return ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Component />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
