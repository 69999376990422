import React, { lazy, Suspense } from 'react';

const AppRoutes = lazy(() => import('./AppRoutes'));
const MorganCreekRoutes = lazy(() => import('./MorganCreekRoutes'));

export const Routes = ({ site = 'www.bitwiseinvestments.com' }) => {
  switch (site) {
    case 'www.digitalassetindexfund.com':
      return (
        <Suspense fallback={<div />}>
          <MorganCreekRoutes />
        </Suspense>
      );
    case 'app.bitwiseinvestments.com':
      return (
        <Suspense fallback={<div />}>
          <AppRoutes />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={<div />}>
          <AppRoutes />
        </Suspense>
      );
  }
};
