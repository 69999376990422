import _ from 'lodash';
import assign from 'lodash/assign';
import merge from 'lodash/merge';
import set from 'lodash/set';
import { combineReducers } from 'redux';

const defaultUser = {
  onboardingStep: 'start',
  entity: 'individual',
  userType: 'selfDirected',
};

function user(state = defaultUser, action) {
  const copiedState = assign({}, state);
  switch (action.type) {
    case 'UPDATE_USER':
      return set(copiedState, action.path, action.value);
    case 'MERGE_USER':
      return merge(copiedState, action.user);
    case 'LOGOUT_USER':
      return defaultUser;
    case 'LOAD_USER':
      return action.user;
    default:
      return state;
  }
}

function investments(state = null, action) {
  switch (action.type) {
    case 'LOAD_INVESTMENTS':
      return action.investments;
    case 'LOGOUT_USER':
      return null;
    default:
      return state;
  }
}

function subscribees(state = null, action) {
  switch (action.type) {
    case 'LOAD_SUBSCRIBEES':
      return action.subscribees;
    case 'LOGOUT_USER':
      return null;
    default:
      return state;
  }
}

function clients(state = null, action) {
  switch (action.type) {
    case 'LOAD_CLIENTS':
      return action.clients;
    case 'LOGOUT_USER':
      return null;
    default:
      return state;
  }
}

function clientApplications(state = null, action) {
  switch (action.type) {
    case 'LOAD_CLIENT_APPLICATIONS':
      return action.clientApplications;
    case 'LOGOUT_USER':
      return null;
    default:
      return state;
  }
}

function selectedUserId(state = null, action) {
  switch (action.type) {
    case 'SET_SELECTED_USER_ID':
      return action.selectedUserId;
    case 'LOGOUT_USER':
      return null;
    default:
      return state;
  }
}

function selectedInvestmentId(state = null, action) {
  switch (action.type) {
    case 'SET_SELECTED_INVESTMENT_ID':
      return action.selectedInvestmentId;
    case 'LOGOUT_USER':
      return null;
    default:
      return state;
  }
}

function alertMessage(state = null, action) {
  switch (action.type) {
    case 'SET_MESSAGE':
      return action.message;
    case 'REMOVE_MESSAGE':
      return null;
    default:
      return state;
  }
}

const initialModalState = {
  modalType: null,
  modalProps: {},
};

function modal(state = initialModalState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case 'HIDE_MODAL':
      return initialModalState;
    default:
      return state;
  }
}

const initialResourcesState = {
  entities: {},
  posts: {},
  postsForEntity: {},
  links: {},
  linksForEntity: {},
};

function resources(state = initialResourcesState, action) {
  const copiedState = _.assign({}, state);
  switch (action.type) {
    case 'SET_FEATURED_POST':
      copiedState.featuredPost = action.featuredPost;
      return copiedState;
    case 'SET_POPULAR':
      copiedState.popular = action.popular;
      return copiedState;
    case 'SET_HOME_PAGE_RESOURCES':
      copiedState.homePageResources = action.homePageResources;
      return copiedState;
    case 'SET_ADVISOR_LETTERS':
      copiedState.advisorLetters = action.advisorLetters;
      return copiedState;
    case 'SET_POSTS_FOR_ENTITY':
      copiedState.entities[action.slug] = action.entity;
      for (const post of action.posts) {
        if (!copiedState.posts[post.fields.slug]) {
          copiedState.posts[post.fields.slug] = post;
        }
      }
      copiedState.postsForEntity[action.slug] = action.posts;
      return copiedState;
    case 'SET_ALL_POSTS':
      for (const post of action.posts) {
        if (!copiedState.posts[post.fields.slug]) {
          copiedState.posts[post.fields.slug] = post;
        }
      }
      return copiedState;
    default:
      return state;
  }
}

const reducers = combineReducers({
  user,
  investments,
  subscribees,
  clients,
  clientApplications,
  selectedUserId,
  selectedInvestmentId,
  alertMessage,
  modal,
  resources,
});

export default reducers;
