import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Routes } from './Routes';

class App extends Component {
  componentDidMount() {
    const user = localStorage && localStorage.getItem('user');
    const investments = localStorage && localStorage.getItem('investments');

    if (user) {
      this.props.dispatch({
        type: 'LOAD_USER',
        user: JSON.parse(user),
      });
    }

    if (investments) {
      this.props.dispatch({
        type: 'LOAD_INVESTMENTS',
        investments: JSON.parse(investments),
      });
    }

    const query = queryString.parse(this.props.location.search);
    if (query && query.gclid) {
      this.props.dispatch({
        type: 'UPDATE_USER',
        path: 'gclid',
        value: query.gclid,
      });
    }
  }

  render() {
    const styles = {
      container: {
        color: '#222222',
        fontFamily: 'NeueHaasUnicaPro',
        fontSize: 16,
        fontWeight: 400,
      },
    };

    return (
      <div style={styles.container}>
        <Routes site={process.env.REACT_APP_WEBSITE} />
      </div>
    );
  }
}

export default withRouter(connect()(App));
